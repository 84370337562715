'use client';

import { suspend } from 'suspend-react';
import { LiveQueryProvider } from 'next-sanity/preview';

// suspend-react cache is global, so we use a unique key to avoid collisions
const UniqueKey = Symbol('@lib/sanity/client');

export default function PreviewProvider({
  children,
  token,
}: {
  children: React.ReactNode;
  token: string;
}): JSX.Element {
  const { client } = suspend(() => import('@lib/sanity/client'), [UniqueKey]);
  if (!token) {
    throw new TypeError('Missing Sanity preview token');
  }
  return (
    <LiveQueryProvider client={client} logger={console} token={token}>
      {children}
    </LiveQueryProvider>
  );
}

'use client';

import * as React from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NextAppDirEmotionCacheProvider from './emotion-cache';

type CssVarsProviderProps = React.ComponentProps<typeof CssVarsProvider>['theme'];

export default function ThemeRegistry({
  children,
  ...props
}: {
  children: React.ReactNode;
  cacheKey: string;
  theme: CssVarsProviderProps;
}): JSX.Element {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: props.cacheKey, prepend: true }}>
      <CssVarsProvider theme={props.theme}>
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </NextAppDirEmotionCacheProvider>
  );
}

'use client';

import { forwardRef } from 'react';
import Link from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xxl: true;
    '2xl': true;
  }
}

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

const LinkBehaviour = forwardRef<HTMLAnchorElement, LinkProps>(function LinkBehaviour(props, ref) {
  const { href, ...others } = props;
  return <Link href={href} ref={ref} {...others} />;
});

const extendedTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#FC5300',
        },
        secondary: {
          main: '#739A30',
        },
      },
    },
  },
  typography: {
    fontFamily: ['var(--font-rubik)', '"Helvetica Neue"', 'Arial'].join(','),
  },
  breakpoints: {
    values: {
      xxs: 300,
      xs: 480,
      sm: 768,
      md: 960,
      lg: 1200,
      xl: 1440,
      xxl: 1728,
      '2xl': 1920,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderBottom: '1px solid #DEE9F8',
            boxShadow: 'none',
            background: 'transparent',
            width: '100%',
            ':last-of-type': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
            '&:before': {
              content: 'none',
            },
            '&.Mui-expanded': {
              marginBottom: 0,
            },
          }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: '#000000',
            flexDirection: 'row-reverse',
            fontWeight: 500,
            paddingLeft: '1rem',
            '&.Mui-expanded': {
              minHeight: 'inherit',
            },
            '&.Mui-focusVisible': {
              backgroundColor: '#DEE9F8',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '0.9375rem',
            },
            [theme.breakpoints.up('md')]: {
              fontSize: '1rem',
            },
          }),
        content: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-expanded': {
              margin: '12px 0 !important',
            },
          }),
        expandIconWrapper: ({ theme }) =>
          theme.unstable_sx({
            transition: 'none',
            '&.Mui-expanded': {
              transform: 'none',
            },
          }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontSize: '0.9375rem',
            fontWeight: 300,
            lineHeight: '1.375rem',
            paddingLeft: '2rem',
          }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa',
          }),
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontSize: '0.75rem',
          }),
        separator: ({ theme }) =>
          theme.unstable_sx({
            mx: '0.125rem',
          }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontWeight: 600,
            lineHeight: '1rem',
            py: '0.625rem',
            px: '1.25rem',
            textDecoration: 'none !important',
            textTransform: 'none',
            '&.Mui-focusVisible': {
              boxShadow: '0 0 0 3px currentColor',
              borderRadius: '0.5rem ',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '0.875rem',
            },
            [theme.breakpoints.up('md')]: {
              fontSize: '1rem',
            },
          }),
        sizeSmall: ({ theme }) =>
          theme.unstable_sx({
            [theme.breakpoints.down('md')]: {
              py: '0.625rem',
              fontSize: '0.875rem',
            },
            [theme.breakpoints.up('md')]: {
              py: '0.75rem',
              fontSize: '1rem',
            },
          }),
        sizeMedium: ({ theme }) =>
          theme.unstable_sx({
            [theme.breakpoints.down('md')]: {
              py: '0.75rem',
              fontSize: '0.875rem',
            },
            [theme.breakpoints.up('md')]: {
              py: '0.875rem',
              fontSize: '1rem',
            },
          }),
        sizeLarge: ({ theme }) =>
          theme.unstable_sx({
            [theme.breakpoints.down('md')]: {
              fontSize: '1rem',
              py: '0.75rem',
              minHeight: '2.75rem',
            },
            [theme.breakpoints.up('md')]: {
              fontSize: '1.25rem',
              py: '1.125rem',
              minHeight: '3.5rem',
            },
          }),
        containedSecondary: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
          }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehaviour,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiDialog-paper': {
              borderRadius: '0.5rem',
              margin: '1rem',
              [theme.breakpoints.up('md')]: {
                margin: '2.5rem',
              },
              [theme.breakpoints.up('lg')]: {
                margin: '4rem',
              },
            },
          }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              boxShadow: '0 0 0 3px currentColor',
              borderRadius: '0.5rem ',
            },
          }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: '#FAFAFA',
            borderRadius: '0.375rem',
            '&::before, &::after': {
              content: 'none',
            },
          }),
        input: ({ theme }) =>
          theme.unstable_sx({
            height: '1.1875rem',
          }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: '#808080',
            fontSize: '0.875rem',
          }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      } as Partial<MuiLinkProps> | undefined,
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.MuiButtonBase-root.Mui-selected': {
              backgroundColor: '#E5EDD8',
            },
            '&.MuiButtonBase-root.Mui-focusVisible': {
              backgroundColor: '#D9E6C4',
            },
          }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }),
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: () => <KeyboardArrowDownIcon className="h-5 w-5" />,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-focused': {
              boxShadow: '0 0 0 2px #E5EDD8',
              borderRadius: '0.125rem ',
            },
            '.MuiSvgIcon-root': {
              position: 'absolute',
              right: '0.3125rem',
              top: 'calc(50% - 0.4375em)',
              pointerEvents: 'none',
              color: 'inherit',
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent !important',
              },
            },
          }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
          }),
      },
    },
  },
});

export default extendedTheme;
